 //Traen informacion del state
export const menu = ( state ) => (state.menu)

export const wings = ( state ) => {
    return{
        ...state.menu.wings,
        ...state.menu.combos
    }
}

export const burguers = ( state ) => {
    return{
        ...state.menu.burguer
    }
}

export const coldDrinks = ( state ) => {
    return{
        ...state.menu.drinks.colds
    }
}

export const hotDrinks = ( state ) => {
    return{
        ...state.menu.drinks.hot
    }
}

export const sodas = ( state ) => {
    return{
        ...state.menu.drinks.sodas
    }
}

export const desserts = ( state ) => {
    return{
        ...state.menu.crepers
    }
}

export const dogs = ( state ) => {
    return{
        ...state.menu.dogs
    }
}

export const extras = ( state ) => {
    return{
        ...state.menu.extras
    }
}

export const creating = ( state ) => {
    return{
        ...state.creating
    }
}

export const creatingPrice = ( state ) => {
    let price = state.creatingPrice
    return{
        price
    }
}

export const tickets = ( state ) => {
    return state.tickets
}

export const closedTickets = ( state ) => {
    return state.closed
}