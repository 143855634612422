// Son sincronas y hacen las modificaciones al state
//  actions -> mutations -> state
// export const myAction = ( state ) => {

// }

export const menu = ( state, menu ) => {
  state.menu = { ...menu }
}

export const addItem = (state) => {
  state.tickets.push(state.creating)
}

export const creating = (state, item) => {
  state.creating.push(item)
  state.creatingPrice += item.price
  
}

export const deleteCreating = ( state ) => {
  state.creating = []
  state.creatingPrice = 0
  state.editingTicket = ''
}

export const tickets = ( state, item ) => {
  state.tickets = item
}

export const editingTicket = ( state, item ) => {
  state.editingTicket = item
}

export const setCreating = ( state, item ) => {
  item.items.forEach( item => {
    state.creating.push(item)
  })
  state.creatingPrice = item.price
}

export const closed = ( state, item ) => {
  state.closed = item
}