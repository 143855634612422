// Lo que importa de una accion es desestructurarlo y tomar el commit
// Pueden ser acciones asincronas que pueden mandar a llamar una mutacion

// Aqui se haran las solicitudes a la api 

// export const myAction = async({ commit }) => {

// }

import africaApi from "@/api/africaApi.js"
import dayjs from "dayjs"

export const getMenu = async({ commit }) => {
  try{
    const { data } = await africaApi.get('/menu.json')
    commit('menu', data)
  }catch(error){
    console.log(error)
  }
}
export const addItem = async ({commit, state}) => {
  const ticket = {
    items: [],
    price: '',
    date: dayjs().format('YYYY-MM-DD HH:mm:ss')
  }
  if(state.editingTicket){
    ticket.items = state.creating
    ticket.price = state.creatingPrice
    try {
      await africaApi.put(`/tickets/open/${state.editingTicket}.json`, ticket)
    } catch (error) {
      console.log(error)
    }
    commit('addItem')
    commit('deleteCreating')
    commit('deleteEditingTicket')
  }
  else{
    try{
      const ticket = {
        items: state.creating,
        price: state.creatingPrice,
        date: dayjs().format('YYYY-MM-DD HH:mm:ss')
      }
      console.log(ticket)
      await africaApi.post('/tickets/open.json', ticket)
    }catch(error){
      console.log(error)
    }
    commit('addItem')
    commit('deleteCreating')
  }
  
}
export const creating = ({commit}, item) => {
  commit('creating',item)
}
export const deleteCreating = ({commit}) => {
  commit('deleteCreating')
}
export const getTickets = async ({commit}) => {
  try {
    const { data } = await africaApi.get('/tickets/open.json')
    commit('tickets', data)
  } catch (error) {
    console.log(error);
  }
}
export const otherItem = async ({commit}, ticket) => {
  commit('editingTicket', ticket.ticket)
  commit('setCreating', ticket)
}
export const closeTicket = async ({commit}, ticket) => {
  try {
    await africaApi.delete(`/tickets/open/${ticket.ticket}.json`)
  } catch (error) {
    console.log(error)
  }
  delete ticket.ticket
  try{
    await africaApi.post('/tickets/closed.json', ticket)
  }
  catch(error){
    console.log(error)
  }
  commit('closeTicket', ticket)
}
export const getClosed = async ({commit}) => {
  try {
    const { data } = await africaApi.get('/tickets/closed.json')
    commit('closed', data)
  } catch (error) {
    console.log(error);
  }
}